import { collection, deleteDoc, doc, updateDoc } from "firebase/firestore"

export class Birthday {
    date = null
    location = null

    constructor(data = null) {
        if (data) {
            this.date = data.date.toDate()
            this.location = data.location
        }
    }

    toData() {
        return {
            date: this.date,
            location: this.location
        }
    }
}
export class Attendee {
    #id = null

    sub = null
    company = null
    attended = false
    attendedTimestamp = null
    appointment = null
    driver = null
    dispatcher = null
    timestamp = null
    status = null

    constructor(appointment, doc = null) {
        this.appointment = appointment
        if (doc) {
            this.#id = doc.id

            const data = doc.data()
            this.sub = data.sub
            this.company = data.company
            this.driver = data.driver
            this.dispatcher = data.dispatcher
            this.attended = data.attended == true
            this.timestamp = data.timestamp.toDate()
            this.status = data.status
            if (data.attendedTimestamp) {
                this.attendedTimestamp = data.attendedTimestamp.toDate()
            }
        }
    }

    compare(attendee) {
        if (attendee.driver == this.driver) {
            return true
        }
        return false
    }

    async setAttended(attended) {
        this.attended = attended
        this.attendedTimestamp = new Date()
        await updateDoc(this.ref, this.toData())
    }

    async setCompany(companyId) {
        this.company = companyId
        await updateDoc(this.ref, this.toData())
    }

    async getDriver() {
        
    }

    toData() {
        const data = {
            sub: this.sub,
            company: this.company,
            attended: this.attended,
            driver: this.driver,
            dispatcher: this.dispatcher,
            attendedTimestamp: this.attendedTimestamp,
            timestamp: this.timestamp,
            status: this.status
        }
        return data
    }

    async remove() {
        await deleteDoc(this.ref)
    }

    get ref() {
        return doc(collection(this.appointment.ref, "attendee"), this.#id)
    }

    get id() {
        return this.#id
    }

}
