import { addDoc, collection, deleteDoc, doc, getDoc, setDoc } from "firebase/firestore"
import { Birthday } from "./Attendee"

export const AbsenceReasons = {
    krank: "Krank",
    urlaub: "Urlaub",
    nichtErschienen: "Nicht Erschienen",
    zuSpät: "zu spät",
    dienstartVortag: "Dienstart Vortag"
}

export class DriverModule {

    date = null
    module = null
    appointment = null
    attended = true
    absenceReason = null

    constructor(data) {
        if (data) {
            this.date = data.date.toDate()
            this.module = data.module
            this.appointment = data.appointment
            this.attended = data.attended,
            this.absenceReason = data.absenceReason
        }
    }
    toData() {
        return {
            date: this.date,
            module: this.module,
            appointment: this.appointment,
            attended: this.attended ?? true,
            absenceReason: this.absenceReason ?? null
        }
    }
}
export class Driver {

    #id = null

    company = null

    personalID = null
    email = null
    firstName = null
    lastName = null
    birthName = null
    birthday = null
    gender = null
    modules = []
    imported = null
    lockImport = null
    risk = 0
    licenseDate = null
    licenseSerialNumber = null
    fqnNumber = null
    fqnDate = null
    licenseClasses = []
    active = null

    constructor(company, doc = null) {
        this.company = company
        if (doc) {
            this.#id = doc.id

            const data = doc.data()
            this.fromData(data)
        }
    }
    fromData(data) {
        this.personalID = data.personalID
        this.email = data.email
        this.firstName = data.firstName
        this.lastName = data.lastName
        this.birthName = data.birthName || null
        if (data.birthday) {
            this.birthday = new Birthday(data.birthday)
        }
        if (data.gender) {
            this.gender = data.gender
        }
        this.imported = data.imported == true
        this.active = data.active
        this.lockImport = data.lockImport == true
        if (data.risk) {
            this.risk = data.risk
        }
        if (data.licenseDate) {
            this.licenseDate = data.licenseDate.toDate()
        }
        this.licenseSerialNumber = data.licenseSerialNumber || null
        this.fqnNumber = data.fqnNumber|| null
        if (data.fqnDate) {
            this.fqnDate = data.fqnDate.toDate()
        }
        this.licenseClasses = data.licenseClasses || []
        for (const module of data.modules) {          
            this.modules.push(new DriverModule(module))
        }
      
    }
    async toFirestore() {
        const driverRef = collection(this.company.firestoreRef, "driver")
        if (this.#id) {
            await setDoc(doc(driverRef, this.#id), this.#toData(), { merge : true })
        } else {
            const addedDoc = await addDoc(driverRef, this.#toData())
            this.#id = addedDoc.id
        }
    }
    async fromFirestore(id) {
        this.#id = id
        const driverRef = collection(this.company.firestoreRef, "driver")
        const driverDoc = await getDoc(doc(driverRef, this.#id))
        if (!driverDoc.exists()) {
            return false
        }
        this.fromData(driverDoc.data())
        return true
    }
    async delete() {
        const driverRef = collection(this.company.firestoreRef, "driver")
        if (this.#id) {
            await deleteDoc(doc(driverRef, this.#id), this.#toData(), { merge : true })
        }
    }
    
    removeModule(moduleNumber) {
        this.modules = this.modules.filter((module)=> module.module != moduleNumber)
    }

    addModule(module) {
        this.modules.push(module)
    }
    getModule(moduleNumber) {
        for (const module of this.modules) {
            if (module.module == moduleNumber) {
                return module
            }
        }
        return null
    }
    #toData() {
        const data = {}
        if (this.personalID) {
            data.personalID = this.personalID
        }
        if (this.firstName) {
            data.firstName = this.firstName
        }
        if (this.lastName) {
            data.lastName = this.lastName
        }
        if (this.birthName) {
            data.birthName = this.birthName
        } else {
            data.birthName = null
        }
        if (this.birthday) {
            data.birthday = this.birthday.toData()
        }
        if (this.email) {
            data.email = this.email
        }
        if (this.gender) {
            data.gender = this.gender
        }
        if (this.risk) {
            data.risk = this.risk
        }
        if (this.licenseDate) {
            data.licenseDate = this.licenseDate
        }
        if (this.licenseSerialNumber) {
            data.licenseSerialNumber = this.licenseSerialNumber
        }
        if (this.fqnDate) {
            data.fqnDate = this.fqnDate
        }
        if (this.fqnNumber) {
            data.fqnNumber = this.fqnNumber
        }
        if (this.licenseClasses) {
            data.licenseClasses = this.licenseClasses
        }
        if (this.lockImport) {
            data.lockImport = true
        }
        if (this.active !== undefined){
            data.active = this.active
        }
        data.modules = []
        for (const module of this.modules) {
            data.modules.push(module.toData())
        }        
        return data
    }
    get id() {
        return this.#id
    }
}