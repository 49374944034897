

const state = {
    addresses: [
        {
            street: "NEW-Niederrhein mobil und aktiv Mönchengladbach GmbH, Rheinstraße",
            number: "70",
            postcode: "41065",
            location: "Mönchengladbach",
            building: "Raum E41",
            attendeesCount: 25
        },
        {
            street: "NEW-Niederrhein mobil und aktiv Mönchengladbach GmbH, Rheinstraße",
            number: "70",
            postcode: "41065",
            location: "Mönchengladbach",
            building: "Raum 113",
            attendeesCount: 17
        },
        {
            street: "Stadtwerke Neuss, EG, Moselstraße",
            number: "25-27",
            postcode: "41464",
            location: "Neuss",
            building: "Werkstatt",
            attendeesCount: 25
        },
        {
            street: "SWK MOBIL GmbH, Gatherhofstraße",
            number: "8",
            postcode: "47804",
            location: "Krefeld",
            building: "Raum V055",
            attendeesCount: 25
        },
        {
            street: "SWK MOBIL GmbH, Gatherhofstraße",
            number: "8",
            postcode: "47804",
            location: "Krefeld",
            building: "Raum V057",
            attendeesCount: 18
        }
    ]
}
const actions = {
    
}
export default { state, actions }